<template>
  <div :before-change="validationForm">
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <!-- images -->
          <b-col cols="12">
            <b-card :title="$t('Show_Vendor.Images')">
              <b-row>
                <b-col md="12">
                  <div align="center" class="text-center">
                    <h4>{{ $t("Show_Vendor.Profile_Picture") }}</h4>
                    <b-media class="mb-1 mr-auto">
                      <b-avatar
                        class="rounded"
                        ref="previewEl"
                        :src="img"
                        size="100"
                      />
                    </b-media>
                    <div class="mb-4 ">
                      <b-button
                        variant="primary"
                        @click="$refs.refInputEl.click()"
                      >
                        <input
                          ref="refInputEl"
                          type="file"
                          class="d-none"
                          @change="uploadImage"
                        />
                        <feather-icon icon="EditIcon" class=" " />
                      </b-button>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <!-- personal Details -->
          <b-col cols="12">
            <b-card :title="$t('Show_Vendor.Personal_Details')">
              <b-row>
                <b-col md="6">
                  <!-- firstname -->
                  <b-form-group
                    v-b-popover.hover.top="'Please Enter First Name'"
                    :label="$t('Show_Vendor.First_Name')"
                    label-for="firstname"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="firstname"
                      rules="required"
                    >
                      <b-form-input
                        id="firstname"
                        v-model="vendorData.firstname"
                        :state="errors.length > 0 ? false : null"
                        placeholder="jhon"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- lastname -->
                <b-col md="6">
                  <b-form-group
                    v-b-popover.hover.top="'Please Enter Last Name'"
                    :label="$t('Show_Vendor.Last_Name')"
                    label-for="lastname"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="lastname"
                      rules="required"
                    >
                      <b-form-input
                        id="lastname"
                        v-model="vendorData.lastname"
                        :state="errors.length > 0 ? false : null"
                        placeholder="wick"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Email -->
                <!-- <b-col md="6">
                                    <b-form-group v-b-popover.hover.top="'Please Enter Valid Email'" :label="$t('Show_Vendor.Email')" label-for="email">
                                        <validation-provider #default="{ errors }" name="email" rules="required|email">
                                            <b-form-input id="email" v-model="vendorData.email" type="email" :state="errors.length > 0 ? false:null" placeholder="Example@MobileMasr.com" />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col> -->
                <!-- BirthDate -->
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Birthdate"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('Show_Vendor.Birthdate')"
                      label-for="Birthdate"
                      :state="errors.length > 0 ? false : null"
                    >
                      <flat-pickr
                        v-model="vendorData.birthdate"
                        class="form-control"
                        placeholder="12/12/2020"
                        :config="{
                          maxDate: maxDate,
                        }"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- phone -->
                <b-col md="6">
                  <b-form-group
                    v-b-popover.hover.top="'Please Enter Phone'"
                    :label="$t('Show_Vendor.Land_Phone')"
                    label-for="phone"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Phone"
                      rules="required|integer"
                    >
                      <b-form-input
                        id="phone"
                        v-model="vendorData.phone"
                        :state="errors.length > 0 ? false : null"
                        placeholder="0225XXXXXX"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>

          <!-- submit button -->
          <b-col cols="12">
            <b-card>
              <b-button
                variant="primary"
                block
                type="submit"
                @click.prevent="validationForm"
              >
                {{ $t("Show_Vendor.Submit") }}
              </b-button>
            </b-card>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
        <ul v-for="(values , index) in errors_back" :key="index">
          <li v-for="(value , valIndex) in values" :key="valIndex">{{ value }}</li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>

<script>

import { required, email, integer } from "@validations";
import flatPickr from "vue-flatpickr-component";
import { heightTransition } from "@core/mixins/ui/transition";
import moment from "moment";
export default {
  components: {
    flatPickr,
  },
  
  mixins: [heightTransition],
  data() {
    return {
      id: 0,
      countryID: "",
      cityID: "",
      city: {},
      success_message: "",
      showDismissibleAlert: false,
      errors_back: [],
      required,
      email,
      integer,
      img: "",
      img2: "",
      img3: "",
      filed: "",
      upplodedFile: null,
      areaOptions: [
        {
          value: "select_value",
          text: "Select Value",
        },
      ],
      areaSelect: null,
      self_managementOptions: [
        {
          value: "select_value",
          text: "Select Value",
        },
        {
          value: "1",
          text: "True",
        },
        {
          value: "0",
          text: "False",
        },
      ],
      self_managementSelected: {
        text: "",
        value: "",
      },
      account_managerSelected: {
        text: "",
        value: "",
      },
      account_managerOptions: [
        {
          value: "select_value",
          text: "Select Value",
        },
      ],
      vendorData: {
        firstname: "",
        lastname: "",
        idnumber: "",
        email: "",
        storename: "",
        birthdate: "",
        address: "",
        phone: "",

        area: {
          city_id: "",
        },
        orderlocation: "",
        password: "",
        password_confirmation: "",
        phoneNumber: [
          {
            id: "",
            mobile: "",
          },
        ],
      },
      sendData: {
        firstname: "",
        lastname: "",
        idnumber: "",
        email: "",
        storename: "",
        birthdate: "",
        address: "",
        phone: "",
        area_id: "",
        orderlocation: "",
        self_management: null,
      },
      countryOptions: [
        {
          value: "select_value",
          text: "Select Value",
        },
      ],
      countrySelect: {
        text: "",
        value: "",
      },
      cityOptions: [
        {
          value: "select_value",
          text: "Select Value",
        },
      ],
      citySelect: {
        text: "",
        value: "",
      },
      areaOptions: [
        {
          value: "select_value",
          text: "Select Value",
        },
      ],
      maxDate: null,
      areaSelect: {
        text: "",
        value: "",
      },
      typeSelect: {
        text: "",
        value: "",
      },
      typeOptions: [
        {
          text: "Company",
          value: "company",
        },
        {
          text: "Personal",
          value: "personal",
        },
      ],
    };
  },
  watch: {
    "vendorData.phoneNumbers": function() {
      this.initTrHeight();
    },
    countrySelect: function() {
      if (this.countrySelect != null && this.countrySelect.text != "") {
        this.countrySelected();
      } else {
        this.countrySelect = null;
        this.citySelect = null;
        this.areaSelect = null;
      }
    },
    citySelect: function() {
      if (this.citySelect != null && this.citySelect.text != "") {
        this.citySelected();
      } else {
        this.citySelect = null;
        this.areaSelect = null;
      }
    },
    self_managementSelected: function() {
      if (this.self_managementSelected.value == "0") {
        this.getAdmins();
      }
    },
  },
  created() {
    this.maxDate = moment()
      .subtract(18, "years")
      .format("YYYY-MM-DD");
    // const dFormat = moment(d, "MM DD YYYY");
    this.id = this.$route.params.id;
    axios
      .get("profile")
      .then((result) => {
        this.vendorData = result.data.data;
        this.img = this.vendorData.profile_picture;
        this.img2 = this.vendorData.idfront_picture;
        this.img3 = this.vendorData.idback_picture;
        for (let ind in this.typeOptions) {
          if (this.vendorData.type === this.typeOptions[ind].value) {
            this.typeSelect.value = this.vendorData.type;
            this.typeSelect.text = this.typeOptions[ind].text;
          }
        }

        const CityI = this.vendorData.area;
        axios
          .get("cities/" + CityI.city.id.toString() + "/areas")
          .then((result) => {
            const data = result.data.data;
            for (let are in data) {
              this.areaOptions.push({
                text: data[are].name,
                value: data[are].id.toString(),
              });
              if (this.vendorData.area.id == data[are].id) {
                this.areaSelect.value = data[are].id.toString();
                this.areaSelect.text = data[are].name;
              }
            }
          })
          .catch((err) => {
            this.errors_back.length = 0;
            if (err.response.data.data != null) {
              if (this.isString(err.response.data.data)) {
                this.errors_back.push({
                  error: err.response.data.data,
                });
              } else {
                this.errors_back = err.response.data.data;
              }
              this.showDismissibleAlert = true;
            } else {
              this.errors_back = [];
              this.errors_back.push({
                error: "internal server error",
              });
              this.showDismissibleAlert = true;
            }
          });
        this.countryID = this.vendorData.area.city;
        axios
          .get("countries")
          .then((result) => {
            const data = result.data.data;
            for (let ind in data) {
              this.countryOptions.push({
                text: data[ind].name,
                value: data[ind].id.toString(),
              });
              if (data[ind].id == this.countryID.country.id) {
                this.countrySelect.value = data[ind].id.toString();
                this.countrySelect.text = data[ind].name;
              }
            }
          })
          .catch((err) => {
            this.errors_back.length = 0;
            if (err.response.data.data != null) {
              if (this.isString(err.response.data.data)) {
                this.errors_back.push({
                  error: err.response.data.data,
                });
              } else {
                this.errors_back = err.response.data.data;
              }
              this.showDismissibleAlert = true;
            } else {
              this.errors_back = [];
              this.errors_back.push({
                error: "internal server error",
              });
              this.showDismissibleAlert = true;
            }
          });
        axios
          .get("countries/" + this.countryID.country.id.toString() + "/cities")
          .then((result) => {
            const data = result.data.data;
            for (let ind in data) {
              this.cityOptions.push({
                text: data[ind].name,
                value: data[ind].id.toString(),
              });
              if (this.vendorData.area.city.id == data[ind].id) {
                this.citySelect.value = data[ind].id.toString();
                this.citySelect.text = data[ind].name;
              }
            }
          })
          .catch((err) => {
            this.errors_back.length = 0;
            if (err.response.data.data != null) {
              if (this.isString(err.response.data.data)) {
                this.errors_back.push({
                  error: err.response.data.data,
                });
              } else {
                this.errors_back = err.response.data.data;
              }
              this.showDismissibleAlert = true;
            } else {
              this.errors_back = [];
              this.errors_back.push({
                error: "internal server error",
              });
              this.showDismissibleAlert = true;
            }
          });
        if (this.vendorData.self_management == true) {
          this.self_managementSelected.value = "1";
          this.self_managementSelected.text = "True";
        } else {
          this.self_managementSelected.value = "0";
          this.self_managementSelected.text = "false";
          this.getAdmins();
          this.account_managerSelected.text =
            this.vendorData.account_manager.firstname +
            " " +
            this.vendorData.account_manager.lastname;
          this.account_managerSelected.value = this.vendorData.account_manager.id.toString();
        }
      })
      .catch((err) => {
        this.errors_back.length = 0;
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            });
          } else {
            this.errors_back = err.response.data.data;
          }
          this.showDismissibleAlert = true;
        } else {
          this.errors_back = [];
          this.errors_back.push({
            error: "internal server error",
          });
          this.showDismissibleAlert = true;
        }
      });
  },
  methods: {
    // sumbit new role
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (this.img.includes("data:image/")) {
            this.sendData.profile_picture = this.img
              .split(",")
              .map((item) => item.trim());
            this.sendData.profile_picture = this.sendData.profile_picture[1];
          }
          if (this.img2.includes("data:image/")) {
            this.sendData.idfront_picture = this.img2
              .split(",")
              .map((item) => item.trim());
            this.sendData.idfront_picture = this.sendData.idfront_picture[1];
          }
          if (this.img3.includes("data:image/")) {
            this.sendData.idback_picture = this.img3
              .split(",")
              .map((item) => item.trim());
            this.sendData.idback_picture = this.sendData.idback_picture[1];
          }
          this.sendData.firstname = this.vendorData.firstname;
          this.sendData.lastname = this.vendorData.lastname;
          this.sendData.idnumber = this.vendorData.idnumber;
          this.sendData.email = this.vendorData.email;
          this.sendData.storename = this.vendorData.storename;
          this.sendData.birthdate = this.vendorData.birthdate;
          this.sendData.address = this.vendorData.address;
          this.sendData.orderlocation = this.vendorData.orderlocation;
          this.sendData.phone = parseInt(this.vendorData.phone);
          this.sendData.area_id = this.areaSelect.value;

          this.sendData.self_management = this.self_managementSelected.value;
          if (this.sendData.self_management == false) {
            this.sendData.account_manager_id = this.account_managerSelected.value;
          }

          axios
            .post("update-profile", this.sendData)
            .then((result) => {
              this.$swal({
                position: "center",
                icon: "success",
                title: "Your work has been saved",
                showConfirmButton: false,
                timer: 1500,
              });
              this.$router.back(1);
            })
            .catch((err) => {
              this.$swal({
                position: "center",
                icon: "error",
                title: "Error!",
                showConfirmButton: false,
                timer: 1500,
              });
              this.errors_back.length = 0;

              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  });
                } else {
                  this.errors_back = err.response.data.data;
                }
                this.showDismissibleAlert = true;
              } else {
                this.errors_back = [];
                this.errors_back.push({
                  error: "internal server error",
                });
                this.showDismissibleAlert = true;
              }
            });
        }
      });
    },
    // get admins
    getAdmins() {
      axios
        .get("admins/account-manager")
        .then((result) => {
          this.account_managerOptions = [
            {
              value: "select_value",
              text: "Select Value",
            },
          ];
          const data = result.data.data;
          for (let index in data) {
            this.account_managerOptions.push({
              text: data[index].firstname + " " + data[index].lastname,
              value: data[index].id.toString(),
            });
          }
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    wait(ms) {
      var start = new Date().getTime();
      var end = start;
      while (end < start + ms) {
        end = new Date().getTime();
      }
    },
    // cencode profile picture
    uploadImage(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      let reader = new FileReader();
      let vm = this;
      reader.onload = (e) => {
        this.img = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    // encode id front pictuer
    uploadImage2(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage2(files[0]);
    },
    createImage2(file) {
      let reader = new FileReader();
      let vm = this;
      reader.onload = (e) => {
        this.img2 = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    // encode id back picture
    uploadImage3(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage3(files[0]);
    },
    createImage3(file) {
      let reader = new FileReader();
      let vm = this;
      reader.onload = (e) => {
        this.img3 = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    countrySelected() {
      this.city = false;
      axios
        .get("countries/" + this.countrySelect.value + "/cities")
        .then((result) => {
          const data = result.data.data;
          this.citySelect = null;
          this.areaSelect = null;
          this.cityOptions = [
            {
              value: "select_value",
              text: "Select Value",
            },
          ];
          for (let ind in data) {
            this.cityOptions.push({
              text: data[ind].name,
              value: data[ind].id.toString(),
            });
          }
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    citySelected() {
      this.area = false;
      axios
        .get("cities/" + this.citySelect.value + "/areas")
        .then((result) => {
          const data = result.data.data;
          this.areaSelect = null;
          this.areaOptions = [
            {
              value: "select_value",
              text: "Select Value",
            },
          ];
          for (let ind in data) {
            this.areaOptions.push({
              text: data[ind].name,
              value: data[ind].id.toString(),
            });
          }
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    isString(value) {
      return typeof value === "string" || value instanceof String;
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
